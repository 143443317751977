<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.team_member">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Team Member</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this team member?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>
				<ti-form-layout>
					<template #sidebar>
						<v-col cols="12">
							<ti-image-upload v-model="team_member.image_id" label="Image" :image-id="team_member.image_id" :key="team_member.id"></ti-image-upload>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="team_member.quote" :rules="validations.quote" label="Quote"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="team_member.quote_by" :rules="validations.quote_by" label="Quote By"></v-text-field>
						</v-col>
						<v-col cols="12">
<!--							<v-file-input v-model="team_member.quote_logo" label="Quote Logo"></v-file-input>-->
							<ti-image-upload v-model="team_member.quote_logo_id" label="Quote Logo" :image-id="team_member.quote_logo_id" :key="team_member.quote_logo_id"></ti-image-upload>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="team_member.twitter"  label="Twitter"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="team_member.linkedin"  label="LinkedIn"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="team_member.instagram"  label="Instagram"></v-text-field>
						</v-col>

					</template>
					<template>
						<v-col cols="12">
							<v-text-field v-model="team_member.name" :rules="validations.name" label="Name"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="team_member.title" label="Title"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="team_member.bio_short" :rules="validations.bio" label="Bio Short"></v-text-field>
						</v-col>
						<v-col cols="12">
							<ti-editor v-model="team_member.bio" label="Bio"></ti-editor>
						</v-col>

					</template>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {sync} from "vuex-pathify";
import models from '@/models'

export default {

	name: "TeamMembersEdit",
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		team_member: sync('team_members/team_member'),
	},
	data: () => ({
		valid: false,
		validations: {
			name: validationRules('name', ['required', 'minLength:2']),
		}
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('team_members/getTeam_member!', this.id)
			} else {
				this.team_member = models.TeamMember()
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.team_members.update(this.id, this.team_member)
						.then(response => {
							this.$toast.add('Team Member updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'TeamMembers'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.team_members.create(this.team_member)
						.then(response => {
							this.$toast.add('Team Member created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'TeamMembers'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.team_members.delete(this.id, this.track)
				.then(response => {
					this.$toast.add('Team Member deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'TeamMembers'})
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.statusText, 'error')
				})
		},
	}
}
</script>