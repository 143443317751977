import _isFunction from "lodash/isFunction";

const rules = {
	required: (fieldName = 'field') => ([
		v => !!v || `${fieldName} is required`,
	]),
	minLength:(fieldName = 'field', limit) => ([
		v => (v && v.length >= limit) || `${fieldName} must be greater than ${limit}`,
	]),
	maxLength:(fieldName = 'field', limit) => ([
		v => (v && v.length <= limit) || `${fieldName} must be less than ${limit}`,
	]),
	betweenLength:(fieldName = 'field', min, max) => ([
		v => (v && v.length >= min && v.length <= max) || `${fieldName} must be between ${min} and ${max}`,
	]),
	email:(fieldName = 'field') => ([
		v => /.+@.+\..+/.test(v) || `${fieldName} must be valid`,
	]),
	matchString:(fieldName = 'field', stringToMatch) => ([
		v => v === stringToMatch || `${fieldName} must match`,
	]),
}
const evalRule = (fieldName, rule) => {
	const args = rule.split(":");
	const functionName = args.shift();
	args.unshift(fieldName)

	if(_isFunction(rules[functionName])){
		return rules[functionName].apply(null, args)
	}
	else {
		console.log(`${functionName} is not a valid function`)
		return []
	}
}

const validationRules = (fieldName, rules = []) => {
	let rulesArray = []
	rules.forEach((rule) => {
		rulesArray = [...rulesArray, ...evalRule(fieldName, rule)]
	})

	return rulesArray
}

export default validationRules